import React, { useState } from 'react'

const IntroductionVideo = () => {

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const handlePlayVideo = () => {
        setIsVideoPlaying(true);
    };

    return (
        <div className="full-glow-background-bottom">
            <div className="glow-content">
                <div className="wrapper bg-transparent my-16 py-16">
                    <div className='glow-background'>
                        <div className='grid lg:grid-cols-2 gap-16'>
                            <div className='rounded-2xl overflow-hidden'>
                                    <iframe className='w-full' height="500" src="https://www.youtube.com/embed/mZuni-Y4o4M?si=i1n1RUcsm0tkN2Ep" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <div className='flex items-center'>
                                <div>
                                    <div className='bg-gradient-to-r from-[#133D56] to-[#6756D4] h-[5px] w-16'></div>
                                    <h1 className='h2 mt-5 font-bold'>Work Together</h1>
                                    <p className='text-[#718096] mt-5 text-lg leading-7'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroductionVideo